import React from "react"
import { Container, Row, Col, Button, Form, Breadcrumb } from 'react-bootstrap'
import { Section } from '../components/Section'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PatternImg from "../images/line-pattern.png"
import AframeImg from '../images/aframe.png'
import verticalImg from '../images/vertical.png'
import regularImg from '../images/regular.png'
import PointerLeft from '../images/pointer-left.svg'
import '@material/react-select/dist/select.css';
import styled from 'styled-components'
import Ripples from 'react-ripples'
import StickyBox from "react-sticky-box";

const FromWrapper = styled.div`
 .form-wrapper{
   //max-width: 426.66px;
   transition: all 0.3s cubic-bezier(0.85, 0.11, 0.58, 1);
  //  @media(max-width:1280px){
  //   max-width: 376.66px;
  //  }
 }

//  @media(max-width:1024px){
//   .form-wrapper{ max-width:100%; margin-left:auto; margin-right:auto;}
//    position:relative !important;
//  }
`

class ProductDetail extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // this.state = {
  //   //   scrolling: false,
  //   // }
  //   // this.handleScroll = this.handleScroll.bind(this)
  // }
  // componentDidMount() {
  //   // window.addEventListener('scroll', this.handleScroll);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }
  // handleScroll(event) {


  //   if (window.scrollY === 0 && this.state.scrolling === true) {
  //     this.setState({ scrolling: false });

  //   }
  //   else if (window.scrollY !== 0 && this.state.scrolling !== true) {
  //     this.setState({ scrolling: true });
  //   }

  // }
  render() {
    return (
      <Layout>
        <SEO title="Building Guide" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className='position-relative'>
            <Breadcrumb className='beadcrumbs-outer'>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Building Guide</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section className='section carport-solution' pt='100px' pb='100px'>
          <Container>
            <Row>
              <Col lg={7} xl={8}>
                <div className='section-heading' data-aos='fade-up'>
                  <span className='section-subtitle'>Steel Structures</span>
                  <h2 className='section-title'>BUILDING GUIDE</h2>
                </div>
                <div className="section-content" data-aos='fade-up' data-aos-delay='100'>
                  <h4 className='text-secondary mb-lg-5 mt-lg-5'>THE BENEFITS OF STEEL STRUCTURES</h4>
                  <p>The steel buildings we offer here at Tennessee are designed and engineered with the highest-quality, galvanized steel components. Not only are they strong and durable, but they are versatile, long lasting, and easy to maintain. They also adapt very easily to your ever-changing needs, no matter if they are personal, residential, agricultural, or commercial.</p>
                  <p className='mt-4'>A few of the other benefits offered by our galvanized steel structures include, but are not limited to, the following:</p>
                  <ul className='list-check row'>
                    <li className='col-lg-6'>
                      <h5>Rust, Fire, Mold, Mildew, and Pest Resistant</h5>
                    </li>
                    <li className='col-lg-6'>
                      <h5>Easy to Install</h5>
                    </li>
                    <li className='col-lg-6'>
                      <h5>Affordable</h5>
                    </li>
                    <li className='col-lg-6'>
                      <h5>Stronger</h5>
                    </li>
                    <li className='col-lg-6'>
                      <h5>Cost-Effective</h5>
                    </li>
                    <li className='col-lg-6'>
                      <h5> And so many more!</h5>
                    </li>
                  </ul>
                </div>
                <Section className='section roof-styles-section' pb='100px' pt='100px'>
                  <Container>
                    <Row>
                      <Col xl={12}>
                        <div className='section-heading'>
                          <span className='section-subtitle'>Roofing Styles</span>
                          <h2 className='section-title'>What you need to know</h2>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='roof-styles'>
                          <Row>
                            <Col xl={5}>
                              <div className='circle-shape'>
                                <img className='pointer-left' src={PointerLeft} alt='...' />
                                <span className='building-type'>BEST</span>
                                <img src={verticalImg} className='img-building' alt='...' />
                              </div>
                            </Col>
                            <Col xl={7}>
                              <div className='section-heading'>
                                <span className='section-subtitle'>Best Roof Style & Highly Recommended</span>
                                <h4 className='section-title tex-primary title-underline'>Vertical Roof Style</h4>
                                <p>The vertical roof style, which is also an A-frame style, is highly recommended for areas that are prone to extreme weather conditions. While it is built for all climate types, it is suitable no matter where you are located. They are installed with additional framing pieces and vertically aligned panels that protect your valuables from all weather conditions. Keep in mind, it is required for structures longer than 36’.</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={5}>
                              <div className='circle-shape'>
                                <img className='pointer-left' src={PointerLeft} alt='...' />
                                <span className='building-type'>Better</span>
                                <img src={AframeImg} className='img-building' alt='...' />
                              </div>
                            </Col>
                            <Col xl={7}>
                              <div className='section-heading'>
                                <span className='section-subtitle'>Affordable Roof Style & Better Quality</span>
                                <h4 className='section-title tex-primary title-underline'>A-Frame Horizontal Roof Style</h4>
                                <p>The vertical roof style, which is also an A-frame style, is highly recommended for areas that are prone to extreme weather conditions. While it is built for all climate types, it is suitable no matter where you are located. They are installed with additional framing pieces and vertically aligned panels that protect your valuables from all weather conditions. Keep in mind, it is required for structures longer than 36’.</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={5}>
                              <div className='circle-shape'>
                                <img className='pointer-left' src={PointerLeft} alt='...' />
                                <span className='building-type'>Good</span>
                                <img src={regularImg} className='img-building' alt='...' />
                              </div>
                            </Col>
                            <Col xl={7}>
                              <div className='section-heading'>
                                <span className='section-subtitle'>Budget Roof Style & Good Quality</span>
                                <h4 className='section-title tex-primary title-underline'>Regular Roof Style</h4>
                                <p>Our regular roofing system is the most economically built and priced option offered in the metal building industry. This standard, curved edge roof design is installed with horizontal paneling that easily channels all rain, snow, and other debris off the ends of the structure. Due to its simple framing, it is best suited for areas that only experience mild to moderate weather conditions.</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Section>
                <Section className='section roof-styles-section' pb='100px' pt='100px' bgBefore={`url(${PatternImg})`}>
                  <Container>
                    <Row>
                      <Col xl={12}>
                        <div className='section-heading'>
                          <h2 className='section-title'>Standard Features</h2>
                          <p>Our structures can be designed and tailored to your exact specifications, but there are also various standard options and prices to choose from. Some of these options do vary based on the area you are located in; for example, areas with higher snowfall ratings have standard, 4’ centers, whereas areas with less snowfall have 5’ centers. We’ve provided a list below with some of our standard sizes and available features.</p>
                        </div>
                        <ul className='list-check'>
                          <li>
                            <h5>Steel Gauge Framing Sizesr</h5>
                            <p>The industry’s standard framing size is our 14-gauge option with a diameter of 2 ½” x 2 ½”. We also offer a stronger, more durable steel framing with a diameter of 2 ¼” x 2 ¼”; 12-gauge. These framing options are available no matter where you’re located; however, there are some areas that require 12-gauge steel framing.</p>
                          </li>
                          <li>
                            <h5>Anchors</h5>
                            <p>We offer four different types of anchors here, including asphalt, concrete, mobile home, and rebar. These anchors are only used for specific foundation types, which your building specialist can provide more information about.</p>
                          </li>
                          <li>
                            <h5>Certified or Non-Certified  </h5>
                            <p>Depending on the area you live in, you may or may not be required to certify your structure. While it depends on your location, the weather in your area, and the requirements of your local building department, you can always choose to certify your structure if it isn’t required.</p>
                          </li>
                          <li>
                            <h5>Colors</h5>
                            <p>In addition to our standard color options, there are also premium colors available to purchase. You can choose your ideal color combination from these colors for your roof, walls, and trim.</p>
                          </li>
                          <li>
                            <h5>Additional Features</h5>
                            <p>Other features we offer here at Tennessee include our garage doors, walk-in doors, windows, and roof styles. You can customize your metal building with these features, or you can ask your building specialist for assistance.</p>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </Section>
              </Col>
              <Col lg={5} xl={4}>
                <StickyBox offsetTop={170}>
                  <FromWrapper>
                    <div className='form-wrapper'>
                      <div className='section-heading'>
                        <h2 className='section-title'>Request To Call</h2>
                      </div>
                      <Form>
                        <div className='section-content'>

                          <div className="form-group">
                            <input type="text" id="name" className="form-control" required />
                            <label className="form-control-placeholder" htmlFor="name">First Name*</label>
                          </div>
                          <div className="form-group">
                            <input type="text" id="name" className="form-control" required />
                            <label className="form-control-placeholder" htmlFor="name">Last Name*</label>
                          </div>
                          <div className="form-group">
                            <input type="text" id="name" className="form-control" required />
                            <label className="form-control-placeholder" htmlFor="name">Contact No*</label>
                          </div>
                          <div className="form-group">
                            <input type="text" id="name" className="form-control" required />
                            <label className="form-control-placeholder" htmlFor="name">Email Address</label>
                          </div>
                          <div className="form-group">
                            <input type="text" id="name" className="form-control" required />
                            <label className="form-control-placeholder" htmlFor="name">Zip Code</label>
                          </div>
                          <div className="form-group">
                            <select className='form-control' required>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </select>
                            <label className="form-control-placeholder" htmlFor="name">State</label>
                          </div>
                          <div className="form-group">
                            <textarea type="text" id="name" className="form-control" required></textarea>
                            <label className="form-control-placeholder" htmlFor="name">Message</label>
                          </div>
                        </div>
                        <div className='section-footer'>
                          <div className='d-block mx-auto text-center'>
                            <Ripples><Button variant="secondary" type="submit" className='btn-border'>Send Message</Button></Ripples>
                          </div>
                        </div>
                      </Form>
                    </div>

                  </FromWrapper>
                </StickyBox>
              </Col>
            </Row>
          </Container>
        </Section>

      </Layout>
    )
  }
}

export default ProductDetail
